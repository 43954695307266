import * as Sentry from '@sentry/nextjs'
import { Dict, init, Mixpanel } from 'mixpanel-browser'

let mixpanelGlobalton: Mixpanel | null = null
export const initMixpanel = (token: string): Promise<Mixpanel> => {
  return new Promise<Mixpanel>((resolve, reject) => {
    if (mixpanelGlobalton) {
      resolve(mixpanelGlobalton)
    } else {
      try {
        init(token, {
          loaded: (mp: Mixpanel) => {
            mixpanelGlobalton = mp
            resolve(mixpanelGlobalton)
          },
        })
      } catch (error) {
        Sentry.captureException(error)
        reject(error)
      }
    }
  })
}

const swallowAndLogException = (fn: () => void) => {
  try {
    fn()
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const mixpanelTrack = (eventName: string, properties?: Dict) => {
  if (mixpanelGlobalton) {
    const mp = mixpanelGlobalton // assignment to lock in type narrowing
    swallowAndLogException(() => {
      mp.track(eventName, properties)
    })
  }
}

export const mixpanelTrackPage = (pageUrl: string): void => {
  mixpanelTrack('View Page', { 'Page Url': pageUrl })
}

type Identity = {
  userId: string
  emailAddress: string
}
export const identify = ({ userId, emailAddress }: Identity): void => {
  if (mixpanelGlobalton) {
    mixpanelGlobalton.identify(userId)
    mixpanelGlobalton.people.set({
      $email: emailAddress,
    })
  }
  Sentry.setUser({
    email: emailAddress,
    id: userId,
  })
}
