import { useEffect } from 'react'
import { useUser } from '@auth0/nextjs-auth0/client'
import { setUser } from '@sentry/core'

import { identify } from '@/utils/instrumentation'

export default function InstrumentationIdentityManager() {
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      const { sub: userId, email: email } = user
      if (userId && email) {
        identify({ userId, emailAddress: email })
      }
    } else {
      setUser(null)
    }
  }, [user])
  return null
}
