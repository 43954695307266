// https://www.split.io/blog/integrating-feature-flags-in-nextjs-react-applications/
// https://help.split.io/hc/en-us/articles/360038825091-React-SDK

import { useUser } from '@auth0/nextjs-auth0/client'
import { useSplitClient } from '@splitsoftware/splitio-react'

import config from './config'
import { getUserOrganizationId } from './server/identity'

export const splitConfig = config.splitConfig

// Simpler approach using the new useSplitClient hook from split compared to our other repos
// because useSplitClient now gives us isReady, we don't have to track it ourselves
function _baseUseFeatureFlag(flagName: string) {
  const { user } = useUser()
  const userId = user?.sub
  const splitKey = Boolean(userId) ? `user:${userId}` : 'anon'

  const { client: splitClient, isReady } = useSplitClient({
    splitKey,
    attributes: {
      organization: getUserOrganizationId(user) || false,
    },
  })

  if (isReady && splitClient) {
    const treatment = splitClient.getTreatment(flagName)

    return { isReady, enabled: treatment === 'on' }
  }

  return { isReady, enabled: false }
}

export function useFeatureFlag(flagName: string) {
  return _baseUseFeatureFlag(flagName).enabled
}

/**
 * Use this if you want to also know when the splitClient is ready
 */
export function useFeatureFlagIfReady(flagName: string) {
  return _baseUseFeatureFlag(flagName)
}
