import camelcaseKeys from 'camelcase-keys'
import { createZodFetcher } from 'zod-fetch'

const camelCaseFetcher = async (...args: Parameters<typeof fetch>) => {
  const response = await fetch(...args)
  const result = await response.json()

  return camelcaseKeys(result, { deep: true })
}
export const fetchWithCamelCasedZod = createZodFetcher(camelCaseFetcher)

export const trpcFetcher = async (...args: Parameters<typeof fetch>) => {
  const response = await fetch(...args)

  // we force reload 401s to either force a session refresh or if the user is truly logged out
  // get auth0 serverside middleware to do it's thing
  if (response.status === 401) {
    window.location.reload()
  }

  return response
}
