import { createContext, useContext, useEffect, useState } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { UserProvider } from '@auth0/nextjs-auth0/client'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import { AppProps } from 'next/app'
import { Source_Sans_3 } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Toaster } from 'sonner'

import config from '@/config'
import { splitConfig } from '@/split'
import createEmotionCache from '@/styles/createEmotionCache'
import theme from '@/styles/theme'
import { api } from '@/utils/api'
import { initMixpanel } from '@/utils/instrumentation'

import InstrumentationIdentityManager from '../components/InstrumentationIdentityManager'

export const sourceSans3 = Source_Sans_3({
  weight: ['400'],
  subsets: ['latin'],
})

const clientSideEmotionCache = createEmotionCache()

if (config.mixpanelToken) {
  initMixpanel(config.mixpanelToken)
}

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default api.withTRPC(App)

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }: MyAppProps) {
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Slater Frontend</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <style jsx global>{`
          html {
            font-family: ${sourceSans3.style.fontFamily};
          }
        `}</style>
      </Head>
      <SplitFactoryProvider config={splitConfig}>
        <ListDropDownProvider>
          <DefaultSearchDataProvider>
            <SearchHistoryDrawerProvider>
              <ThemeProvider theme={createTheme(theme)}>
                <CssBaseline />
                <UserProvider>
                  <UrlChangeListener />
                  <Component {...pageProps} />
                  <Toaster richColors position="top-center" />
                  <InstrumentationIdentityManager />
                </UserProvider>
              </ThemeProvider>
            </SearchHistoryDrawerProvider>
          </DefaultSearchDataProvider>
        </ListDropDownProvider>
      </SplitFactoryProvider>
    </CacheProvider>
  )
}

export function UrlChangeListener() {
  const router = useRouter()
  const { isSearchHistoryOpen, setIsSearchHistoryOpen } = useContext(SearchHistoryDrawerContext)

  useEffect(() => {
    const pathname = router.pathname
    setIsSearchHistoryOpen(pathname === '/search')
  }, [router, setIsSearchHistoryOpen])

  return <></>
}

type DefaultSearchData = {
  searchQuery: string
  projectName: string
  searchFilters: string[]
}
type DefaultSearchDataType = {
  defaultSearchData: DefaultSearchData
  setDefaultSearchData: React.Dispatch<React.SetStateAction<DefaultSearchData>>
  clearDefaultSearchData: () => void
}
export const DefaultSearchDataContext = createContext<DefaultSearchDataType>({
  defaultSearchData: {
    searchQuery: '',
    projectName: '',
    searchFilters: [],
  },
  setDefaultSearchData: () => {},
  clearDefaultSearchData: () => {},
})
function DefaultSearchDataProvider(props: { children: React.ReactNode }) {
  const [defaultSearchData, setDefaultSearchData] = useState<DefaultSearchData>({
    searchQuery: '',
    projectName: '',
    searchFilters: [],
  })

  function clearDefaultSearchData() {
    setDefaultSearchData({
      searchQuery: '',
      projectName: '',
      searchFilters: [],
    })
  }

  return (
    <DefaultSearchDataContext.Provider
      value={{
        defaultSearchData,
        setDefaultSearchData,
        clearDefaultSearchData,
      }}
    >
      {props.children}
    </DefaultSearchDataContext.Provider>
  )
}

type DropdownStateType = {
  // the main add to list dropdown not in the row, we give it a special id of 'main'
  // so we can pick the state out of the rest (which are keyed with id of row)
  main: boolean
  [key: string]: boolean
}

type ListDropDownContextType = {
  dropdownStates: DropdownStateType
  toggleDropdownState: (key: string) => void
  closeAllDropDowns: () => void
}

// Create the context with default values
export const ListDropDownContext = createContext<ListDropDownContextType>({
  dropdownStates: { main: false },
  toggleDropdownState: () => {},
  closeAllDropDowns: () => {},
})

export function ListDropDownProvider(props: { children: React.ReactNode }) {
  const [dropdownStates, setDropdownStates] = useState<DropdownStateType>({ main: false })

  const toggleDropdownState = (key: string) => {
    setDropdownStates((prevStates) => {
      return {
        ...prevStates,
        [key]: !prevStates[key] || false,
      }
    })
  }

  function closeAllDropDowns() {
    setDropdownStates({ main: false })
  }

  return (
    <ListDropDownContext.Provider
      value={{ dropdownStates, closeAllDropDowns, toggleDropdownState }}
    >
      {props.children}
    </ListDropDownContext.Provider>
  )
}

type SearchHistoryDrawerType = {
  isSearchHistoryOpen: boolean
  setIsSearchHistoryOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const SearchHistoryDrawerContext = createContext<SearchHistoryDrawerType>({
  isSearchHistoryOpen: false,
  setIsSearchHistoryOpen: () => {},
})

function SearchHistoryDrawerProvider(props: { children: React.ReactNode }) {
  const router = useRouter()
  const [isSearchHistoryOpen, setIsSearchHistoryOpen] = useState(router.pathname === '/search')

  return (
    <SearchHistoryDrawerContext.Provider value={{ isSearchHistoryOpen, setIsSearchHistoryOpen }}>
      {props.children}
    </SearchHistoryDrawerContext.Provider>
  )
}
