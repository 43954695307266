import { Claims } from '@auth0/nextjs-auth0'

export function getUserOrganizationId(user: Claims = {}): string | undefined {
  return user['_enterprise_search:organization_id'] || undefined
}

// keep updated (for now) with the result of this query against
// slater prod db:
// select distinct organization_id
// from coman_search_job
// order by organization_id;
const ORG_ID_NAMES: Record<string, string> = {
  partnerslate: 'PartnerSlate Inc',
  danone: 'Danone',
  demo: 'Demo Org',
  fonterra: 'Fonterra',
  kindsnacks: 'Kind Snacks',
  kraftheinz: 'Kraft Heinz',
  mars: 'Mars',
  tippingpoint: 'Tipping Point',
}

export function getUserOrganizationName(user: Claims = {}): string {
  const orgId = getUserOrganizationId(user)
  if (!orgId) {
    throw new Error(`missing organization id for user: ${getUserId(user)}`)
  }

  if (orgId in ORG_ID_NAMES) {
    return ORG_ID_NAMES[orgId]
  }

  throw new Error(`Unknown organization: ${orgId}`)
}

export function getUserId(user: Claims = {}): string | null {
  if (typeof user.sub === 'string') {
    return user.sub
  } else {
    return null
  }
}
