import * as Sentry from '@sentry/nextjs'
import { Integration } from '@sentry/types'

const appEnv = (process.env.NEXT_PUBLIC_APP_ENV || 'localdev') as Environments

type SentryOptionsProps = {
  type: 'client' | 'server'
}
interface ConfigType {
  mixpanelToken: string | null
  sentryOptions: ({ type }: SentryOptionsProps) => Sentry.BrowserOptions
  splitConfig: SplitIO.IBrowserSettings
  appEnv: Environments
}

// Sentry SDK requires this to be a globalton, so we can't create
// a new one inside `sentryOptionsForEnv(...)`
let sentryReplayGlobalton: Integration

// this will cause an error if trying to instantiate replayIntegration in a "server" context
// so we only do this on client and we need to memoize the instance because sentry does not
// want us to make muiltiple versions of the replay instance
function makeSentryIntegrations(type: 'client' | 'server') {
  if (type === 'client') {
    if (!sentryReplayGlobalton) {
      sentryReplayGlobalton = Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      })
    }

    return [sentryReplayGlobalton]
  } else {
    return []
  }
}

// Note: We set the release value for Sentry in the env var SENTRY_RELEASE during CICD instead of defining it here
// per the docs: https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#create-initialization-config-files
const sentryOptionsForEnv = (
  env: Environments,
  type: 'server' | 'client',
): Sentry.BrowserOptions => {
  // server and client base options
  let options: Sentry.BrowserOptions = {
    dsn: 'https://382a5d2972b53f50a6af4104986738b3@o91293.ingest.sentry.io/4506044082618368',
    environment: `slater-frontend-${env}`,
    tracesSampleRate: 1,
  }

  if (type === 'client') {
    options = {
      ...options,
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
      integrations: makeSentryIntegrations(type),
      ignoreErrors: [
        /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
        "Cannot read properties of undefined (reading 'domInteractive')",
      ], // c.f. https://github.com/getsentry/sentry-javascript/issues/3440 https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062 https://github.com/cefsharp/CefSharp/blob/ed1763ee9510dca9e54ab766ea7cf305f71d2aa2/CefSharp/Internals/JavascriptObjectRepository.cs#L298
    }
  }

  return options
}

const SPLIT_CONFIG_NON_PROD: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'ik6tug5uuqj5jig7btga8j6m6i65sfod0ofq',
    key: 'anon',
  },
}

const SPLIT_CONFIG_PROD: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'qrdqo3bcvhdnu6qi0mdrcafrnnecvj30uud6',
    key: 'anon',
  },
}

const MIXPANEL_TOKEN_NON_PROD = '0f28685d06e211f1530820c5826a49a1'
const MIXPANEL_TOKEN_PROD = 'e44e7aadb5e209aed8a2a3bcc582cc4f'

const DISABLED_SENTRY = ({ type }: SentryOptionsProps) => ({})

const localdev: ConfigType = {
  sentryOptions: DISABLED_SENTRY,
  mixpanelToken: MIXPANEL_TOKEN_NON_PROD,
  splitConfig: SPLIT_CONFIG_NON_PROD,
  appEnv,
}
const stg: ConfigType = {
  sentryOptions: ({ type }) => sentryOptionsForEnv(appEnv, type),
  mixpanelToken: MIXPANEL_TOKEN_NON_PROD,
  splitConfig: SPLIT_CONFIG_NON_PROD,
  appEnv,
}
const prd: ConfigType = {
  sentryOptions: ({ type }) => sentryOptionsForEnv(appEnv, type),
  mixpanelToken: MIXPANEL_TOKEN_PROD,
  splitConfig: SPLIT_CONFIG_PROD,
  appEnv,
}

const configs = {
  localdev,
  stg,
  prd,
}

export type Environments = keyof typeof configs
const environment: Environments = appEnv || 'localdev'

const config: ConfigType = configs[environment]

export default config
